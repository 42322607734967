$masters-green: #006747;
$masters-yellow: #f4c630;
$coral: #ff7f50;
$red-orange: #FF5349;
$white: #fff;
$deep-blue: #0F1B2E;
$deep-blue-darker: #0B1422;
$dark-grey: #34495e;
$medium-grey: #95a5a6;
$light-grey: #f7f7f7;
$blackish: #2f2f2f;
$black: #000;
$mild-cream: #f7f6ee;
$mild-cream: #f9f8f3;

// Pantone 2021 Color of the year
$ultimate-grey: #939597;
$illuminating: #F5DF4D;

$body-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;
$body-font: 'Merriweather', serif;
$script-font: 'Sacramento', cursive;

$section-divider-height: 2px;
$section-divider-color: $red-orange;
$section-divider-color-inverted: $white;

/*
 * Text Colors
 */
$t-title-color: $red-orange;
$t-title-color-inverted: $white;
$t-subtitle-color: $red-orange;
$t-subtitle-color-inverted: $white;
$t-arrow-color: $red-orange;
$t-arrow-color-inverted: $white;
$t-link-color: $red-orange;
$t-link-color-inverted: $red-orange;

$t-link-color-hover: $illuminating;
$t-link-color-hover-inverted: $ultimate-grey;

$t-about-color: $mild-cream;
$t-about-color-inverted: $deep-blue;
$t-body-color: $deep-blue;
$t-body-color-inverted: $mild-cream;
$t-footer-color: $mild-cream;
$t-footer-color-inverted: $deep-blue;
$t-copyright-color: $mild-cream;
$t-copyright-color-inverted: $deep-blue-darker;

/*
 * Background colors
 */
$bg-header-color: $white;
$bg-header-color-inverted: $red-orange;

$bg-about-color: $deep-blue;
$bg-about-color-inverted: $mild-cream;

$bg-body-color: $mild-cream;
$bg-body-color-inverted: $deep-blue;

$bg-footer-color: $deep-blue;
$bg-footer-color-inverted: $white;

$bg-copyright-color: $deep-blue-darker;
$bg-copyright-color-inverted: $mild-cream;
