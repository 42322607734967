@import './variables';

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }

a {
  -webkit-tap-highlight-color: hsla(0,0%,0%,0.0);
}

a {
  color: $t-link-color;
  word-wrap: break-word;
  -webkit-transition: color 0.1s ease-in, background 0.1s ease-in;
  -moz-transition: color 0.1s ease-in, background 0.1s ease-in;
  -ms-transition: color 0.1s ease-in, background 0.1s ease-in;
  -o-transition: color 0.1s ease-in, background 0.1s ease-in;
  transition: color 0.1s ease-in, background 0.1s ease-in;

  &:hover, &:focus {
    color: $t-link-color-hover;
    text-decoration: none;
    outline: 0;
  }
}

a:before, a:after {
  -webkit-transition: color 0.1s ease-in, background 0.1s ease-in;
  -moz-transition: color 0.1s ease-in, background 0.1s ease-in;
  -ms-transition: color 0.1s ease-in, background 0.1s ease-in;
  -o-transition: color 0.1s ease-in, background 0.1s ease-in;
  transition: color 0.1s ease-in, background 0.1s ease-in;
}

.smol {
  font-size: 12px;
}

.right {
  text-align: right;
}

h1 {
  font-size: calc(1rem + 5vmin);
}

h5 {
  font-family: $body-font;
  font-size: calc(0.75rem + 1vmin);
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $red-orange;
  margin: 1em 0;
  padding: 0;
  width: 100%;
}

.inverted {
  a {
    color: $t-link-color-inverted;

    &:hover, &:focus {
      color: $t-link-color-hover-inverted;
    }
  }
}
