@import './libs/font-awesome.min.css';
@import './variables';
@import './common';

* {
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: subpixel-antialiased;
  -webkit-tap-highlight-color: hsla(0,0%,0%,0.0);
  user-select: none;
}

html {
  font-size: 12px;
}

body {
  margin: 0;
  font-family: $body-font;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  position: relative;

  .bar {
    background-color: $bg-header-color;
    position: fixed;
    width: 100%;
    top: -200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    font-family: $script-font;
    transition: top ease-in-out 0.5s;
    padding: 30px 0;
    border-bottom: $section-divider-height solid $section-divider-color;
    box-shadow: 0 -1px 4px rgba($blackish, 0.45);

    &.bar--visible {
      top: 0;
    }

    h1 {
      color: $t-title-color;
    }

    h5 {
      color: $t-subtitle-color;
    }
  }

  header {
    position: relative;
    background-color: $bg-header-color;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: $script-font;

    .titles {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-family: $script-font;

      h1 {
        color: $t-title-color;
      }

      h5 {
        color: $t-subtitle-color;
      }
    }

    @keyframes over-here {
      0% {
        transform: translateY(0);
      }

      25% {
        transform: translateY(-5px);
      }

      50% {
        transform: translateY(0);
      }

      100% {
        transform: translateY(0);
      }
    }

    .down-arrow {
      width: 0px;
      height: 0px;
      position: absolute;
      border: 6px solid transparent;
      border-top: 6px solid $t-arrow-color;
      bottom: 20px;
      transition-property: bottom;
      transition-timing-function: ease-in-out;
      animation-duration: 1.2s;
      animation-name: over-here;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;

      &:before {
        position: absolute;
        top: 50%;
        left: -16px;
        content: '';
        height: 4px;
        width: 20px;
        background-color: $t-arrow-color;
        transform: rotate(45deg);
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }

      &:after {
        position: absolute;
        top: 50%;
        right: -16px;
        content: '';
        height: 4px;
        width: 20px;
        background-color: $t-arrow-color;
        transform: rotate(-45deg);
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }
  }

  .about {
    position: relative;
    min-height: 100vh;
    height: 100vh;
    max-height: 100vh;
    background-color: $bg-about-color;
    color: $t-about-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top: $section-divider-height solid $section-divider-color;
    font-size: 1.5rem;
    line-height: 4rem;
    text-align: center;
  }

  .section-title {
    font-family: $script-font;
    color: $red-orange;
    font-size: 4rem;
    padding-right: 40px;
  }

  main {
    position: relative;
    min-height: 100vh;
    background-color: $bg-body-color;
    color: $t-body-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px 0;
    border-top: $section-divider-height solid $section-divider-color;
    border-bottom: $section-divider-height solid $section-divider-color;

    .job {
      margin-bottom: 10px;
      color: $t-body-color;

      &--title {
        font-size: 1.5rem;
        font-weight: 700;
      }

      &--company {
        font-size: 1.35rem;
      }

      &--location {
        font-size: 1.1rem;
      }

      &--description {
        font-size: 1.1rem;
        margin-top: 10px;
      }

      .dates-column {
        display: none;
      }
    }
  }

  footer {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $bg-footer-color;
    padding-top: 60px;

    p {
      color: $t-footer-color;
      margin-bottom: 0;
    }

    h5 {
      color: $t-footer-color;
    }

    span {
      font-size: 1.2rem;
    }

    i {
      padding-right: 8px;
    }

    #copyright {
      background-color: $bg-copyright-color;
      margin-top: 60px;
      padding-top: 15px;
      text-align: right;

      p {
        color: $t-copyright-color;
        margin-bottom: 15px;

        .heart {
          color: $red-orange;
        }

        span {
          font-family: $script-font;
          color: $red-orange;
          font-size: 2rem;
        }
      }

      i {
        padding-right: 0;
      }
    }
  }

  .inverted {
    .bar {
      background-color: $bg-header-color-inverted;
      border-bottom-color: $section-divider-color-inverted;

      h1 {
        color: $t-title-color-inverted;
      }

      h5 {
        color: $t-subtitle-color-inverted;
      }
    }

    header {
      background-color: $bg-header-color-inverted;

      h1 {
        color: $t-title-color-inverted;
      }

      h5 {
        color: $t-subtitle-color-inverted;
      }

      .down-arrow {
        border-top-color: $t-arrow-color-inverted;

        &:before {
          background-color: $t-arrow-color-inverted;
        }

        &:after {
          background-color: $t-arrow-color-inverted;
        }
      }
    }

    .about {
      background-color: $bg-about-color-inverted;
      color: $t-about-color-inverted;
    }

    main {
      background-color: $bg-body-color-inverted;
      border-top-color: $section-divider-color-inverted;
      border-bottom-color: $section-divider-color-inverted;

      .job {
        color: $t-body-color-inverted;
      }
    }

    footer {
      background-color: $bg-footer-color-inverted;

      p {
        color: $t-footer-color-inverted;
      }

      #copyright {
        background-color: $bg-copyright-color-inverted;

        p {
          color: $t-copyright-color-inverted;
        }
      }
    }
  }
}
